<template>
  <div style="width: 1280px;">
    <div id="page1">
      <div id="top">
        <div class="top-span">
          <div class="box1 flex-row">
            <div class="wrap1 flex-col">
              <div class="group2 flex-col"></div>
            </div>
            <span class="info1">皮特宠物</span>
            <el-link :underline="false" class="txt1" type="color:white" href="/">首页</el-link>
            <el-link :underline="false" class="word2" type="color:white" href="/distribution">分销</el-link>
            <el-link :underline="false" class="txt1" type="color:white" href="/register">会员</el-link>
            <el-link :underline="false" class="txt1" type="color:white" href="/shop">商城</el-link>
            <el-link :underline="false" class="word2" type="color:white" href="/login">登录</el-link>
            <div class="wrap2 flex-col">
              <span class="txt2">免费试用</span>
            </div>
          </div>
        </div>
      </div>
      <img id="title-img" src="../../assets/img/index/title.png" style="width: 1280px;" alt="">
    </div>
    <div style="display: flex;margin-top: -90px;justify-content: center;">
      <div
        style="width: 270px;height: 140px;background-image:linear-gradient(to right,#F08E78, #F0AA99);display: flex;">
        <div>
          <img src="../../assets/img/index/zhuanfa.png" alt="">
        </div>
        <div>
          <h4 style="color: #FFFFFF;">2022年实体店经营现状&nbsp;&nbsp;&nbsp;</h4>
          <span style="font-size: 12px;color: #FFFFFF;">十八般武艺：教你提升引流效率!</span><br />
          <span style="font-size: 12px;color: #FFFFFF;float: left;margin-top: 5px;">增进门店转化率</span>
        </div>
      </div>
      <div
        style="width: 270px;height: 140px;background-image:linear-gradient(to right,#2A2F63, #455097);display: flex;">
        <div>
          <img src="../../assets/img/index/yunshu.png" alt="">
        </div>
        <div>

          <h4 style="color: #FFFFFF;">论物流仓储的重要性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h4>

          <span style="font-size: 12px;color: #FFFFFF;">一站式服务平台让你省心又省力</span><br />
          <span style="font-size: 12px;float: left;color: #FFFFFF;margin-top: 5px;">物流+仓储=安心+放心</span>
        </div>
      </div>
      <div
        style="width: 270px;height: 140px;background-image:linear-gradient(to right,#F6FAFF, #F6FAFF);display: flex;">
        <div>
          <img src="../../assets/img/index/fenxi2.png" alt="">
        </div>
        <div>

          <h4>上百个品牌合作商</h4>

          <span style="font-size: 12px;">行业大咖带你少走弯路</span><br />
          <span style="font-size: 12px;float: left;margin-top: 5px;">品牌产业链覆盖</span>
        </div>
      </div>
    </div>
    <div style="margin-top: 60px;display: flex;justify-content: center;color: #373653;">
      <div style="width: 185px;height: 230px;background-color: #F9FAFF;margin: 10px;">
        <div style="margin: 30px;display: flex;flex-direction: column;">
          <div style="width: 100%;"><img src="../../assets/img/index/user2.png" alt=""
                                         style="width: 40px;height: 40px;float: left;"></div>
          <div>
            <h4 style="float: left;">注册客户数</h4>
          </div>
          <div>
            <span style="float: left;font-size: 13px;">注册客户数达160万以上，注册门店达3000个以上。</span>
          </div>
        </div>
      </div>
      <div style="width: 185px;height: 230px;background-color: #F9FAFF;margin: 10px;">
        <div style="margin: 30px;display: flex;flex-direction: column;">
          <div style="width: 100%;"><img src="../../assets/img/index/user.png" alt=""
                                         style="width: 40px;height: 40px;float: left;"></div>
          <div>
            <h4 style="float: left;">最高在线人数</h4>
          </div>
          <div>
            <span style="float: left;font-size: 13px;">APP最高在线人数可达100万人。</span>
          </div>
        </div>
      </div>
      <div style="width: 185px;height: 230px;background-color: #F9FAFF;margin: 10px;">
        <div style="margin: 30px;display: flex;flex-direction: column;">
          <div style="width: 100%;"><img src="../../assets/img/index/fenxi.png" alt=""
                                         style="width: 40px;height: 40px;float: left;"></div>
          <div>
            <h4 style="float: left;">产品种类</h4>
          </div>
          <div>
            <span style="float: left;font-size: 13px;">产品覆盖宠物用品全行业，共有5000余种。</span>
          </div>
        </div>
      </div>
      <div style="width: 185px;height: 230px;background-color: #F9FAFF;margin: 10px;">
        <div style="margin: 30px;display: flex;flex-direction: column;">
          <div style="width: 100%;"><img src="../../assets/img/index/tiepai.png" alt=""
                                         style="width: 40px;height: 40px;float: left;"></div>
          <div>
            <h4 style="float: left;">合作品牌</h4>
          </div>
          <div>
            <span style="float: left;font-size: 13px;">相关合作品牌共有100多个，能有效带动行业发展！</span>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 30px;">
      <h1 style="font-family: SourceHanSansCN-Regular;">了解皮特的详情</h1>
      <h3>全场景全链接的宠物产业链，助力企业升级</h3>
    </div>
    <div style="display: flex;    width: 1280px;">
      <div class="wrap5 flex-col">
        <div class="layer3 flex-col">
          <div class="bd5 flex-row justify-between">
            <img class="pic6" referrerpolicy="no-referrer" src="../../assets/img/index/xiaochengxu.png" />
            <span class="word16">小程序</span>
          </div>
          <div class="bd6 flex-col"></div>
          <span class="word17">您想拥有自己小程序吗？</span>
          <span class="word18">满足于商家获客沉淀、社区运营、商城转换、智能营销等全面需求！</span>
        </div>
      </div>
      <div class="wrap5 flex-col">
        <div class="layer3 flex-col">
          <div class="bd5 flex-row justify-between">
            <img class="pic6" referrerpolicy="no-referrer" src="../../assets/img/index/fenzu.png" />
            <span class="word16">分佣系统</span>
          </div>
          <div class="bd6 flex-col"></div>
          <span class="word17">您想轻松分佣吗？</span>
          <span class="word18">注册分销即可享受平台所有的资源，轻松分佣！</span>
        </div>
      </div>
      <div class="wrap5 flex-col">
        <div class="layer3 flex-col">
          <div class="bd5 flex-row justify-between">
            <img class="pic6" referrerpolicy="no-referrer" src="../../assets/img/index/dianhua.png" />
            <span class="word16">客服</span>
          </div>
          <div class="bd6 flex-col"></div>
          <span class="word17">入驻平台</span>
          <span class="word18">联系客服入驻平台，即可轻松获取所有资源，助力成功！</span>
        </div>
      </div>
    </div>
    <div style="margin-top: 30px;">
      <h1 style="font-family: SourceHanSansCN-Regular;">合作品牌</h1>
      <img src="../../assets/img/index/hezuohuoban.png" alt="">
    </div>
    <foot></foot>
  </div>
</template>
<script>
import Foot from "@/components/foot";

export default {
  components: {
    Foot
  },
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped>
@import "../../assets/css/common.css";
@import "../../assets/css/index.css";

#top {
  position: absolute;
  margin: 10px 0 0 0;
}

.top-span {
  font-size: 12px;
  display: flex;
}

#contet div div {
  width: 300px;
  height: 150px;
  margin: 10px;
  display: flex;
}

.foolt-div div {
  margin: 10px 0 0 50px;
}

</style>
