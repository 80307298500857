<template>
  <div style="width: 1280px;">
    <div style="background-color: #000000;height: 320px; color: #E3E3E3;display: flex;">
      <div style="width: 44%;display: flex;">
        <div style="width: 43%;height: 100%;"></div>
        <div>
          <div style="display: flex;margin:15px 0 0 0px;">

            <div class="group2 flex-col"></div>
            <h3 style="margin: 8px 0 0 24px;;">皮特宠物</h3><strong></strong>

          </div>
          <div style="width: 170px;margin:10px 0 0  0px;">
            <p style="float: left;font-size:13px">地址：四川省成都市龙泉驿区西河镇黄连村4组666号</p>
            <p style="float: left;font-size:13px">驰奔达物联技术有限公司</p>
            <p style="float: left;font-size:13px">Tcl：19113700752</p>
            <p style="float: left;">
              <img src="../assets/img/shop/wechat.png" alt="" style="width: 40px;">
              <img src="../assets/img/shop/ins2.png" alt="" style="width: 40px;">
              <img src="../assets/img/shop/ins.png" alt="" style="width: 40px;">
            </p>
          </div>
        </div>
      </div>
      <div style="width: 57%;">
        <div style="width: 10%;"></div>
        <div style="" class="foot-div">
          <div>

            <h3>产品</h3>

            <p class="p-title">微商城</p>
            <p class="p-title">小程序</p>
            <p class="p-title">企业助手</p>
            <p class="p-title">分佣系统</p>
          </div>
          <div>

            <h3>服务</h3>

            <p class="p-title">广告投放</p>
            <p class="p-title">增长运营</p>
            <p class="p-title">引流截流</p>
            <p class="p-title">整合营销</p>
          </div>
          <div>

            <h3>关于我们</h3>

            <p class="p-title">皮特介绍</p>
            <p class="p-title">企业文化</p>
            <p class="p-title">加入我们</p>
            <p class="p-title">合作联系</p>
          </div>
          <div>

            <h3>联系我们</h3>

            <p class="p-title">入驻咨询</p>
            <p class="p-title"></p>
            <p class="p-title">商家服务</p>
            <p class="p-title"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "foot"
}
</script>

<style scoped>
.p-title {
  margin: 40px 0 0 0px;
  color: #E3E3E3;
  font-size: 12px;
}

.foot-div {
  display: flex;
  justify-content: space-around;
}
.foolt-div div {
  margin: 10px 0 0 50px;
}
</style>
