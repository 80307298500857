<template>
  <div id="app" v-title data-title="皮特宠物官网">
    <center>
      <router-view />

    </center>
  </div>
</template>
<script>

export default {
  name: "App",
  components: {}
};
</script>
<style>
html, body, center, #app {
  height: 100%;
}

#app {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  pading: 0;
}

</style>
