import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import Vuex from "vuex";


Vue.use(ElementUI);
Vue.use(Vuex);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

const store = new Vuex.Store({
  state: {
    username: ""
  },
  mutations: {
    setUserName(state, username) {
      state.username = username;
    }
  }
});

Vue.directive("title", {
  inserted: function(el) {
    document.title = el.dataset.title;
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
