import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/index/index.vue";
// import UserIndex from "../views/user/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    meta: {
      // 页面标题title
      title: "首页"
    }
  },
  {
    path: "/register",
    name: "register",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/register/register.vue")
  },
  {
    path: "/shop",
    name: "shop",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/shop/shop.vue")
  },
  {
    path: "/distribution",
    name: "distribution",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/distribution/distribution.vue")
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login/index.vue")
  },
  {
    path: "/registerUser",
    name: "registerUser",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/registerUser/index.vue")
  },

  {
    path: "/user",
    name: "index",
    component: () => import("../views/user/index.vue"),   // 这里配置首页默认路由跳转的页面组件
    children: [   // 添加子路由
      {
        path: "info",  // 注意点：路径前面不要带/，否则无法跳转，在这里吃过亏
        name: "info",
        components: {   // 注意参数名带s
          table: () => import("../views/user/info.vue")  // 这里的table跟首页的router-view标签的name一致，才会在首页的路由视图进行跳转，看3.2
        }
      },
      {
        path: "changePwd",  // 注意点：路径前面不要带/，否则无法跳转，在这里吃过亏
        name: "changePwd",
        components: {   // 注意参数名带s
          table: () => import("../views/user/changePwd.vue")  // 这里的table跟首页的router-view标签的name一致，才会在首页的路由视图进行跳转，看3.2
        }
      },
      {
        path: "out",  // 注意点：路径前面不要带/，否则无法跳转，在这里吃过亏
        name: "out",
        components: {   // 注意参数名带s
          table: () => import("../views/user/out.vue")  // 这里的table跟首页的router-view标签的name一致，才会在首页的路由视图进行跳转，看3.2
        }
      },
      {
        path: "inputMoney",  // 注意点：路径前面不要带/，否则无法跳转，在这里吃过亏
        name: "inputMoney",
        components: {   // 注意参数名带s
          table: () => import("../views/user/inputMoney.vue")  // 这里的table跟首页的router-view标签的name一致，才会在首页的路由视图进行跳转，看3.2
        }
      },
      {
        path: "moneyLog",  // 注意点：路径前面不要带/，否则无法跳转，在这里吃过亏
        name: "moneyLog",
        components: {   // 注意参数名带s
          table: () => import("../views/user/moneyLog.vue")  // 这里的table跟首页的router-view标签的name一致，才会在首页的路由视图进行跳转，看3.2
        }
      }
    ]
  },
  {
    path: "/user/index",
    name: "userIndex",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/index.vue")
  },
  {
    path: "/user/changePassword",
    name: "changePassword",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/changePwd.vue")
  },
  {
    path: "/user/out",
    name: "out",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/out.vue")
  },
  {
    path: "/user/inputMoney",
    name: "inputMoney",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/inputMoney.vue")
  },
  {
    path: "/user/moneyLog",
    name: "moneyLog",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/user/moneyLog.vue")
  },
  {
    path: "/goods/index",
    name: "goods",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/goods/index.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
